html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  background-color: rgb(232, 232, 232);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  /* padding: 0 1em 1em 1em; */
  box-sizing: border-box;
}

.header-logo-container {
  display: flex;
  width: 100%;
  margin: 1em 0 0.5em 0;
}

.header-logo {
  align-self: center;
  width: 100%;
}

.header-formerly-container {
  width: 18em;
  border-radius: 45%;
}

.header-formerly-paragraph {
  text-align: center;
  /* padding: 0.75em 0; */
  padding: 0 0 0.5em 0;
  font-size: 1em;
  margin: 0;
  font-style: italic;
}

.header-formerly-paragraph-break {
  display: none;
}

.header-item {
  margin: 0 0 0.5em 0;
}

.header-address-paragraph {
  text-align: center;
  margin: 0.25em 0 ;
  font-size: 1.1em;
}

.header-phone-container {
  display: flex;
  margin: 0.5em 0;
  color: white;
  justify-content: flex-end;
}

.header-phone-logo-container {
  /* width: 2em;
  height: 2em; */
  background-color: rgb(10, 55, 86);
  padding-left: 0.5em;
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-phone-logo {
  /* background-color: rgb(10, 55, 86);
  padding-left: 0.5em;
  border-radius: 15px 0 0 15px; */
  width: 1.5em;
}

.header-phone-number {
  background-color: rgb(10, 55, 86);
  padding: 0.5em 0.5em 0.5em 0.1em;
  margin: 0;
  border-radius: 0 15px 15px 0;
}

.header-hours-container {
  display: none;
}

.header-hours-paragraph {
  text-align: end;
}

.header-hours-span {
  margin: 0.25em 0;
  text-align: end;
}

.nav-bar-links-container {
  background-color: rgb(41, 101, 138);
  height: 3em;
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-bar-nav-link {
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 0.5em;
  font-size: 0.75em;
  text-align: center;
  flex: 1 1;
  justify-content: center;
}

.nav-bar-nav-link:hover{
  background-color: rgb(10, 55, 86);
}

.selected {
  background-color: rgb(10, 55, 86);
}

.home-nav-link {
  border-right-style: solid;
  border-width: 0.2em;
  border-color: rgb(10, 55, 86);
}

.products-nav-link {
  border-right-style: solid;
  border-width: 0.2em;
  border-color: rgb(10, 55, 86);
}

.lighting-fixtures-products-nav-link {
  border-right-style: solid;
  border-width: 0.2em;
  border-color: rgb(10, 55, 86);
}

.parts-service-nav-link {
  border-right-style: solid;
  border-width: 0.2em;
  border-color: rgb(10, 55, 86);
}

.home-screen {
  display: flex;
  flex-direction: column;
}

.home-screen-header {
  text-align: center;
  margin: 0.25em 0;
  font-size: 3em;
  color: rgb(41, 101, 138);
}

.lighting-banner-row {
  /* background-color: rgb(232, 232, 232); */
  /* background-color: rgba(41, 101, 138, 0.712); */
  /* background-color: rgba(48, 99, 131, 0.712); */
  /* background-color: rgba(55, 113, 150, 0.712); */
  background-color: rgb(249, 221, 6);
  display: flex;
  justify-content: center;
  margin: 0.5em 0;
}

.lighting-banner-row-header {
  text-align: center;
  width: 100%;
}

.lighting-banner-nav-link {
  /* color: rgb(241, 175, 7); */
  /* color: rgb(224, 224, 4); */
  /* color: rgb(236, 236, 2); */
  /* color: rgb(248, 248, 1); */
  color: rgb(29, 130, 184);
}

.home-screen-image-gallery {
  display: flex;
}

.home-screen-image-gallery-button-container {
  position: relative;
  width: 0;
  /* display: inline; */
  align-self: center;
  /* right: 5%; */
}

.home-screen-image-gallery-button {
  /* position: absolute; */
  border: none;
  padding: 0;
  font-size: 1.5em;
  height: 3em;
  width: 1em;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.45);
  outline: none;
  cursor: pointer;
}

.home-screen-image-gallery-button:hover {
  background-color: rgba(100, 99, 99, 0.5);
}

/* .home-screen-image-gallery-previous-button-container {
  left: 0.5em;
} */

.home-screen-image-gallery-next-button-container {
  right: 1.5em;
}

.home-screen-image {
  width: 100%;
}

.introduction-row {
  margin: 1em 0 0.25em 0;
}

.introduction-row-header {
  text-align: center;
  margin: 0 0 0.25em 0;
  color: rgb(41, 101, 138);
}

.introduction-row-paragraph {
  text-align: center;
  margin: 0;
  padding: 0 1em;
}

.introduction-paragraph-emphasis {
  color: rgb(41, 101, 138);
  font-weight: bold;
}

.browse-products-links-container {
  display: flex;
  flex-direction: column;
}

.home-screen-products-nav-link {
  text-align: center;
  margin: 0.5em 0;
  padding: 0.25em 0.25em;
  max-width: 15em;
  color: rgb(41, 101, 138);
  font-weight: bold;
  font-size: 1.25em;
  border-style: solid;
  align-self: center;
  text-decoration: none;
  background-color: rgb(232, 232, 232);
  width: 75%;
}

.home-screen-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1em;
}

.our-brands-card {
  width: 95%;
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  background-color: rgb(41, 101, 138);
}

.our-brands-image {
  width: 100%;
}

.brands-container {
  /* color: rgb(232, 232, 232); */
  color: white;
  background-color: rgb(41, 101, 138);
  /* height: 13em; */
  /* height: 15em; */
  height: 24em;
}

.brands-container-header {
  margin: 0.5em 0 0 0;
  text-align: center;
  font-size: 1.5em;
}

.brands-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  /* margin: 0.5em 0;
  height: 9em; */
  /* height: 12em; */
  height: 21em;
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

.brand-list-item {
  list-style: none;
  /* margin: 0.15em 0; */
  margin: 0.22em 0;
  text-align: center;
  /* width: 8em; */
  width: 50%;
  height: 48px;
  display: flex;
  justify-content: center;
}

.brand-list-item-anchor {
  /* color: rgb(232, 232, 232); */
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 8em;
  align-items: center;
  font-size: 1.2em;
}

.about-us-card {
  width: 95%;
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
}

.about-us-image-container {
  /* display: flex; */
  /* object-fit: fill; */
}

.about-us-image {
  width: 100%;
  display: flex;
}

.about-us-container {
  /* color: rgb(232, 232, 232); */
  color: white;
  background-color: rgb(41, 101, 138);
}

.about-us-container-header {
  margin: 0.5em 0 0 0;
  text-align: center;
}

.about-us-description {
  margin: 0.5em 0;
  padding: 0 0.5em 0.5em 0.5em;
  text-align: center;
}

.home-screen-description {
  color: rgb(232, 232, 232);
  background-color: rgb(10, 55, 86);
  padding: 0.5em 1em;
}

.home-screen-description-paragraph {
  text-align: center;
  margin: 0.5em 0;
}

.footer {
  /* color: rgb(232, 232, 232); */
  color: white;
  background-color: rgb(41, 101, 138);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding-bottom: 1em;
  width: 100%;
}

.footer-logo-address-container {
  width: 15em;
  align-self: center;
}

.footer-logo {
  width: 100%;
  padding-top: 1em;
}

.footer-address-street {
  margin-bottom: 0;
}

.footer-address-city {
  margin-top: 0.25em;
}

.footer-phone {
  margin-bottom: 0;
}

.footer-store-hours-header {
  margin-bottom: 0.25em;
}

.footer-store-hours-days {
  margin-bottom: 0.25em;
  margin-top: 0;
  text-align: center;
  text-decoration: underline;
}

.footer-store-hours-times {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

.footer-store-hours-closed {
  text-align: center;
  margin-bottom: 0;
}

.footer-connect-with-us-header {
  margin-bottom: 0.25em;
}

.footer-connect-with-us-anchor-container {
  display: flex;
  justify-content: center;
}

.footer-connect-with-us-anchor {
  margin: 0 0.5em;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-connect-with-us-logo {
  width: 1.5em;
  height: 1.5em;
  /* margin: 0 0.25em; */
}

.policies-terms-card {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.policies-terms-navlink {
  text-decoration: none;
  color: white;
  margin: 0.25em 0;
  height: 48px;
}

.privacy-policy-container {
  margin: 0.5em 1em;
  overflow-wrap: break-word;
}

.privacy-policy-container ul{
  -webkit-padding-start: 1.5em;
          padding-inline-start: 1.5em;
}

.terms-conditions-container {
  margin: 0.5em 1em;
  overflow-wrap: break-word;
}

.terms-conditions-container ul{
  -webkit-padding-start: 1.5em;
          padding-inline-start: 1.5em;
}

.cookies-policy-container {
  margin: 0.5em 1em;
  overflow-wrap: break-word;
}

.cookies-policy-container ul{
  -webkit-padding-start: 1.5em;
          padding-inline-start: 1.5em;
}

.ceiling-fans-description {
  text-align: center;
  margin: 1em 0.5em 0 0.5em;
}

.ceiling-fans-description-header {
  color: rgb(41, 101, 138);
  margin-top: 0;
  margin-bottom: 0.25em;
}

.ceiling-fans-description-paragraph {
  margin-top: 0;
}

.ceiling-fans-description-emphasis {
  font-weight: bold;
}

.products-brands-container {
  margin-bottom: 1em;
}

.products-brands-container-header {
  text-align: center;
  color: rgb(41, 101, 138);
  margin-bottom: 0em;
}

.products-brands-logos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products-brands-logo {
  width: 85%;
  height: 8em;
  display: flex;
  justify-content: center;
  margin: 1em;
  box-shadow: 3px 2px 10px black;
}

.products-brands-logo-anchor {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.products-brands-logo-image {
  width: 15em;
  padding: 0 0.5em;
  box-sizing: border-box;
  max-height: 100%;
}

.featured-products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured-products-container-header {
  color: rgb(41, 101, 138);
  /* margin-bottom: 0.25em; */
  margin: 0.25em 0 0.25em 0;
  text-align: center;
}

.featured-products-container-paragraph {
  margin: 0;
  padding: 0 1em;
  text-align: center;
}

.featured-products-categories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.featured-products-card-navlink {
  width: 95%;
  text-decoration: none;
  background-color: rgb(41, 101, 138);
  margin: 1em 0;
}

.featured-products-card {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.featured-products-card-image {
  width: 100%;
  /* height: 300px; */
}

/* .featured-products-card {
  height: 300px;
  width: 400px;
  overflow: hidden;
} */

/* .featured-products-card-image {
  height: auto;
  width: 400px;
} */

.featured-products-card-category {
  color: white;
  background-color: rgb(41, 101, 138);
  margin: 0;
  padding: 0.5em 0;
  font-size: 1.5em;
  text-align: center;
}

.lighting-fixtures-description {
  text-align: center;
  margin: 1em 1em 0 1em;
  margin-bottom: 0;
}

.lighting-fixtures-description-header {
  color: rgb(41, 101, 138);
  margin-top: 0;
  margin-bottom: 0.25em;
}

.lighting-fixtures-description-paragraph {
  margin-top: 0;
  margin-bottom: 0;
}

.lighting-fixtures-description-emphasis {
  font-weight: bold;
}

.featured-lighting-fixture-products-container-header {
  color: rgb(41, 101, 138);
  margin-bottom: 0.25em;
  text-align: center;
}

.featured-lighting-fixture-products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured-ceiling-fans-by-brand-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured-ceiling-fans-by-brand-container-header {
  color: rgb(41, 101, 138);
  /* margin-bottom: 0.25em; */
  margin: 0.25em 0 0.25em 0;
  text-align: center;
}

.category-screen {
  display: flex;
  flex-direction: column;
}

.category-container {
  display: flex;
  flex-direction: column;
}

.category-description-header {
  text-align: center;
  margin-bottom: 0.25em;
  color: rgb(41, 101, 138);
}

.category-description-paragraph {
  text-align: center;
  padding: 0 1em;
  margin: 0;
}

.category-fans-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.category-fan-card-nav-link {
  display: flex;
  flex-direction: column;
}

.category-fan-card-image {
  width: 100%;
  /* height: 350px; */
}

.category-fan-card-name {
  text-align: center;
  margin-top: 0;
}

.category-screen-back-to-products-screen-nav-link {
  width: 14em;
  text-decoration: none;
  text-align: center;
  color: rgb(232, 232, 232);
  background-color: rgb(10, 55, 86);
  padding: 1em;
  border-radius: 5px;
  align-self: center;
  margin: 1em 0;
}

.fan-details-screen {
  display: flex;
  flex-direction: column;
}

.fan-details-container {
  display: flex;
  flex-direction: column;
  /* padding: 0 1em; */
}

.fan-details-header {
  align-self: center;
  text-align: center;
  padding: 0 1em;
}

.fan-details-row {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.sizes-container {
  /* display: flex;
  flex-direction: column;
  text-align: center; */
}

.sizes-header {
  margin-bottom: 0;
}

.sizes-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* margin-top: 0.5em; */
  margin-top: 0.25em;
  /* padding: 0; */
}

.sizes-list-list-item {
  margin-top: 0.25em;
}

.fan-motor-type-header {
  margin-bottom: 0;
}

.fan-motor-type-list {
  margin-top: 0.5em;
}

.fan-motor-type-list-item {
  margin-top: 0.5em;
}

.fan-control-type-header {
  margin-bottom: 0;
}

.fan-control-type-list {
  margin-top: 0.5em;
}

.fan-control-type-list-item {
  margin-top: 0.5em;
}

.fan-location-rating-header {
  margin-bottom: 0;
}

.fan-location-rating-list {
  margin-top: 0.5em;
}

.fan-location-rating-list-item {
  margin-top: 0.5em;
}

.fan-airflow-rating-header {
  margin-bottom: 0;
}

.fan-airflow-rating-list {
  margin-top: 0.5em;
}

.fan-airflow-rating-list-item {
  margin-top: 0.5em;
}

.fan-control-type-header {
  margin-bottom: 0;
}

.fan-control-type-list {
  margin-top: 0.5em;
}

.fan-control-type-list-item {
  margin-top: 0.5em;
}

.smart-fan-control-type-list-item {
  margin-top: 0.5em;
}

.colors-header {
  margin-bottom: 0;
}

.colors-list {
  margin-top: 0.5em;
}

.colors-list-item-body {
  margin-top: 0.5em;
}

.colors-list-body {
  margin-top: 0.5em;
}

.colors-list-body-color-list-item {
  margin-top: 0.5em;
}

.colors-list-body-color-list-item-shown {
  font-weight: 625;
}

.colors-list-item-blades {
  margin-top: 0.5em;
}

.colors-list-blades {
  margin-top: 0.5em;
}

.colors-list-blades-color-list-item {
  margin-top: 0.5em;
}

.colors-list-blades-color-list-item-shown {
  font-weight: 625;
}

.colors-list-item {
  margin-top: 0.5em;
}

.colors-list-item-shown {
  font-weight: 625;
}

.colors-list-item-light {
  margin-top: 0.5em;
}

.colors-list-light {
  margin-top: 0.5em;
}

.colors-list-light-list-item {
  margin-top: 0.5em;
}

.colors-list-light-list-item-shown {
  font-weight: 625;
}

.fan-light-header {
  margin-bottom: 0;
}

.fan-light-details-list {
  margin-top: 0.5em;
}

.fan-light-details-list-item {
  margin-top: 0.5em;
}

.fan-details-image-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.fan-details-image {
  width: 100%;
  align-self: center;
}

.fan-details-image-caption {
  text-align: center;
  margin: 0;
  padding: 0 0.5em;
}

.fan-details-information-container {
  padding: 0 1em;
}

.lighting-fixture-details-image {
  width: 100%;
  align-self: center;
}

.lighting-fixture-dimensions-list-item {
  margin-top: 0.5em;
}

.lighting-fixture-bulb-information-list-item {
  margin-top: 0.5em;
}

.lighting-fixture-bulb-information-list {
  margin-top: 0.5em;
}

.lighting-fixture-bulb-information-container-header {
  margin-bottom: 0;
}

.part-number-header {
  margin-bottom: 0;
}

.part-number-list {
  margin-top: 0.5em;
}

.part-number-list-item {
  margin-top: 0.5em;
}

.parts-accessories-description {
  text-align: center;
  margin: 1em 1em 1.5em 1em;
}

.parts-accessories-description-header {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgb(41, 101, 138);
}

.parts-accessories-description-paragraph {
  margin: 0;
}

.parts-accessories-card {
  display: flex;
  flex-direction: column;
  width: 94%;
}

.parts-accessories-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.parts-accessories-card-image-container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.parts-accessories-card-image {
  width: 100%;
}

.parts-accessories-card-information {
  text-align: center;
  /* color: rgb(232, 232, 232); */
  color: white;
  /* background-color: rgba(41, 101, 138, 0.8); */
  background-color: rgb(41, 101, 138);
  /* outline: solid; */
  outline-color: rgba(41, 101, 138, 0.8);
  outline-width: thick;
  /* border: solid; */
  /* border-color: rgb(232, 232, 232); */
  border-color: white;
  border-width: thin;
  /* margin: 0.75em 1em; */
  margin: 0 0 1em 0;
  padding: 1em;
  box-sizing: border-box;
}

.parts-accessories-card-header {
  margin-top: 0;
  margin-bottom: 0.25em;
}

.parts-accessories-card-list {
  padding: 0;
  margin: 0;
}

.parts-accessories-card-list-item {
  list-style: none;
}

.parts-accessories-card-paragraph {
  margin: 0;
}

/* .map-container div:first-child {
  position: relative !important;
  height: 300px !important;
} */

.map-container {
  height: 300px;
}

.contact-us-map-container {
  display: flex;
  justify-content: center;
}

.contact-us-map {
  display: flex;
}

.info-window-name {
  margin: 0 0 0.5em 0;
}

.info-window-street-address {
  margin: 0;
}

.info-window-address-city {
  margin-top: 0;
}

.info-window-phone {
  margin: 0.5em 0 0.5em 0;
}

.location-row {
  background-color: rgb(10, 55, 86);
  /* color: rgb(232, 232, 232); */
  color: white;
  text-align: center;
  margin: 0 0 1em 0;
  padding: 0.5em 1em;
}

.location-row-header {
  margin: 0;
}

.location-row-street-address {
  margin-top: 0;
}

.contact-us-row {
  display: flex;
  flex-direction: column;
}

.contact-us-card {
  color: rgb(41, 101, 138);
  background-color: rgb(232, 232, 232);
  padding: 0.5em;
  /* margin: 0 0.5em 0.5em 0.5em; */
  flex: 1 1;
}

.contact-us-card-header {
  margin: 0;
}

.contact-us-card-information-phone-email-container {
  display: flex;
}

.contact-us-card-information-phone-email-headers{
  display: flex;
  flex-direction: column;
  margin-right: 0.5em;
  font-weight: bold;
}

.contact-us-card-information-phone-email-information {
  display: flex;
  flex-direction: column;
}

.contact-us-card-information-operation-hours-header {
  font-weight: bold;
  margin-bottom: 0.25em;
}

.contact-us-card-information-operation-hours-container {
  display: flex;
}

.contact-us-card-information-days{
  display: flex;
  flex-direction: column;
  margin-right: 2em;
}

.contact-us-card-information-hours {
  display: flex;
  flex-direction: column;
}

.contactu-us-form-container {
  padding: 0 0.5em 0.5em 0.5em;
  margin: 0 0.5em;
  flex: 1 1;
  box-sizing: border-box;
}

.contact-us-form-header {
  border-bottom-style: solid;
  border-bottom-width: thin;
  padding-bottom: 0.25em;
  margin: 1em 0 0 0;
  /* margin-bottom: 0.5em;
  margin-top: 0.5em; */
}

.contact-us-form {
  display: flex;
  flex-direction: column;
}

.contact-us-form-input-header {
  margin-bottom: 0.25em;
}

.contact-us-form-input {
  box-sizing: border-box;
  width: 100%;
  height: 3em;
}

.contact-us-form-message-input {
  resize: none;
  height: 20em;
}

.contact-us-form-submit-button {
  color: rgb(232, 232, 232);
  background-color: rgb(41, 101, 138);
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.75em;
  padding: 0.75em 0;
  font-weight: bold;
}

.message-sent-successfully-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
  padding: 0 1em;
}

.message-sent-successfully-paragraph {
  text-align: center;
  font-size: 1.6em;
}

.message-sent-successfully-contact-screen-return-navlink {
  text-decoration: none;
  text-align: center;
  color: rgb(232, 232, 232);
  background-color: rgb(10, 55, 86);
  padding: 1em;
  border-radius: 5px;
}

.message-sent-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
  padding: 0 1em;
}

.message-sent-error-paragraph {
  text-align: center;
  font-size: 1.6em;
}

.message-sent-error-contact-screen-return-navlink {
  text-decoration: none;
  text-align: center;
  color: rgb(232, 232, 232);
  background-color: rgb(10, 55, 86);
  padding: 1em;
  border-radius: 5px;
}

.page-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-not-found-container-address {
  font-style: italic;
  margin-bottom: 0;
  text-align: center;
}

.page-not-found-container-paragraph {
  margin-bottom: 1em;
  text-align: center;
}

.page-not-found-back-button {
  cursor: pointer;
  font-size: 1em;
  color: white;
  background-color: rgb(10, 55, 86);
  padding: 0.5em;
  border-radius: 5px;
  margin-bottom: 1em;
}

.loading-screen {
  display: flex;
  flex-direction: column;
}

.loading-container {
  align-items: center;
  justify-content: center;
}

.loading-container-paragraph {
  text-align: center;
}

@media only screen and (min-width: 768px) {

  .header {
    flex-direction: row;
    padding: 0 0.5em 0.25em 0.5em;
  }

  .header-formerly-address-container {
    order: 1;
    align-self: flex-end;
  }

  .header-logo-container {
    order: 2;
    display: flex;
    width: 45%;
    margin: 0.5em 0 1em 0;
    /* margin: 1em 0; */
  }

  .header-formerly-container {
    width: 9em;
    background-color: rgb(10, 55, 86);
    color: white;
    border-radius: 50%;
  }
  
  .header-formerly-paragraph {
    text-align: center;
    padding: 0.75em 0;
    font-size: 1em;
    margin: 1em;
    font-style: none
  }

  .header-formerly-paragraph-break {
    display: inline;
    display: initial;
  }

  .header-item {
    margin: 0.5em 0;
  }

  .header-address-container {
    font-size: 0.95em;
  }

  .header-address-paragraph {
    text-align: left;
    margin-bottom: 0;
    align-self: flex-end;
    font-size: 0.95em;
  }

  .header-phone-hours-container {
    order: 3;
    align-self: flex-end;
    margin: 0;
  }

  .header-hours-container {
    display: inline;
    display: initial;
  }

  .header-hours-span {
    margin: 0.25em 0 0 0;
    font-size: 0.95em;
    text-align: center;
  }

  .header-hours-span-days {
    text-decoration: underline;
  }

  .header-hours-span-hours {
    margin: 0;
  }
  
  .header-hours-operation-header {
    font-size: 1em;
    /* text-align: center; */
  }

  .nav-bar-links-container {
    height: 3.5em;
  }

  .nav-bar-nav-link {
    /* margin: 0 0.5em; */
    padding: 0 0.4em;
    font-size: 1.2em;
  }

  .home-screen-image-gallery {
    align-self: center;
    margin-top: 1em;
  }

  .home-screen-image-gallery-button {
    font-size: 1.5em;
    height: 4em;
    width: 1em;
  }

  .home-screen-image-gallery-previous-button-container {
    left: 0.5em;
  }
  
  .home-screen-image-gallery-next-button-container {
    right: 2em;
  }

  .lighting-banner-row {
    width: 100%;
    /* justify-content: center; */
    align-self: center;
    margin: 1em 0 0 0;
  }

  .lighting-banner-row-header {
    text-align: center;
    font-size: 1.3em;
  }

  .introduction-row-header {
    font-size: 1.75em;
  }

  .introduction-row-paragraph {
    padding: 0 3em;
    font-size: 1.25em;
  }

  .browse-products-links-container {
    flex-direction: row;
    justify-content: space-around;
    margin: 0.5em 0;
  }

  .home-screen-products-nav-link {
    width: 47%;
    font-size: 1.45em;
  }

  .home-screen-list {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .home-screen-list-card-container {
    height: 24em;
  }

  .our-brands-card {
    width: 47%;
  }

  .brand-list-item-anchor {
    font-size: 15pt;
  }

  .about-us-card {
    width: 47%;
  }

  /* .about-us-container {
    height: 13em;
  } */

  .about-us-description {
    padding: 0 1em;
    font-size: 1.3em;
  }

  .home-screen-description-paragraph {
    font-size: 1.25em;
  }

  .footer-logo-address-container {
    width: 17em;
  }

  .footer-logo {
    padding-top: 0;
  }

  .footer-address {
    font-size: 1.2em;
  }

  .footer-store-hours {
    font-size: 1.2em;
  }

  .footer-store-hours-header {
    font-size: 1.3em;
  }

  .footer-connect-with-us {
    font-size: 1.2em;
  }

  .footer-connect-with-us-header {
    font-size: 1.3em;
  }

  .contact-us-row {
    flex-direction: row;
  }

  .contact-us-card {
    padding: 0.5em;
    margin: 0 0 1em 1em;
  }

  .contact-us-card-header {
    margin: 1em 0;
  }

  .contact-us-card-information-phone-email-headers{
    margin-right: 2em;
  }

  .contactu-us-form-container {
    padding: 0.5em;
    margin: 0 0.5em 0.5em;
  }

  .contact-us-form-header {
    margin-top: 0.75em;
  }

  .footer {
    flex-direction: row;
  }

  .privacy-policy-container {
    margin: 2em 2em;
  }
  
  .privacy-policy-container ul{
    -webkit-padding-start: 3em;
            padding-inline-start: 3em;
  }

  .terms-conditions-container {
    margin: 2em 2em;
  }

  .terms-conditions-container ul{
    -webkit-padding-start: 3em;
            padding-inline-start: 3em;
  }

  .cookies-policy-container {
    margin: 2em 2em;
  }

  .cookies-policy-container ul{
    -webkit-padding-start: 3em;
            padding-inline-start: 3em;
  }

  .ceiling-fans-description-header {
    margin-bottom: 0.25em;
    font-size: 2.5em;
  }

  .ceiling-fans-description-paragraph {
    font-size: 1.3em;
  }

  .products-brands-container-header {
    margin-bottom: 0.25em;
    font-size: 2.5em;
  }

  .products-brands-logos-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .products-brands-logo {
    width: 15em;
    height: 10em;
  }

  .products-brands-logo-image {
    width: 15em;
  }

  .lighting-fixtures-description-header {
    margin-bottom: 0.25em;
    font-size: 2.5em;
  }

  .lighting-fixtures-description-paragraph {
    font-size: 1.3em;
  }

  .featured-lighting-fixture-products-container-header {
    margin-bottom: 0.25em;
    font-size: 2em;
  }

  .featured-products-categories-container {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  .featured-products-container-header {
    margin-bottom: 0.25em;
    font-size: 2em;
  }
  
  .featured-products-container-paragraph {
    margin-bottom: 0.25em;
    padding: 0 3em;
    font-size: 1.2em;
  }

  .featured-products-card-navlink {
    width: 350px;
  }

  .featured-products-card-image {
    width: 350px;
    height: 350px;
  }

  .featured-ceiling-fans-by-brand-container-header {
    margin-bottom: 0.25em;
    font-size: 2em;
  }

  .category-description-paragraph {
    margin-bottom: 0.25em;
    padding: 0 3em;
  }

  .category-fan-card-image {
    width: 350px;
  }

  .fan-details-row {
    /* flex-direction: row; */
    flex-direction: column;
  }

  .fan-details-image-container {
    /* order: 1; */
    flex-direction: column;
    justify-content: flex-start;
  }

  .fan-details-image {
    /* width: 60%; */
    width: 100%;
  }

  .fan-details-image-caption {
    padding: 0 1em;
  }

  .sizes-container {
    width: 40%;
    text-align: left;
    text-align: initial;
  }

  .sizes-list {
    align-items: flex-start;
    padding-left: 2.5em;
  }

  .lighting-fixture-details-image {
    width: 60%;
  }

  .lighting-fixture-bulb-information-container {
    width: 40%;
    text-align: left;
    text-align: initial;
  }

  .lighting-fixture-bulb-information-list {
    align-items: flex-start;
    padding-left: 2.5em;
  }

  .parts-accessories-description {
    margin-bottom: 0;
  }

  .parts-accessories-description-header {
    font-size: 2.5em;
  }

  .parts-accessories-description-paragraph {
    font-size: 1.3em;
    margin-bottom: 1.5em;
  }

  .parts-accessories-card-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 1em;
    align-items: center;
  }

  .parts-accessories-card {
    /* width: 95% */
    /* width: 350px; */
    margin: 0.2em;
    width: 46%;
  }

  .parts-accessories-card-image-container {
    margin: 0 2%;
  }

  .parts-accessories-card-image {
    /* width: 350px;
    height: 350px; */
  }

  .parts-accessories-card-information {
    width: 100%;
    height: 11em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2%;
    margin-top: 0;
    padding: 0.5em 0.75em 0.75em;
  }

}

@media only screen and (min-width: 1024px) {

  .header {
    padding: 1em 0;
    justify-content: space-around;
  }

  .header-formerly-address-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .header-formerly-container {
    align-self: center;
    width: 13em;
    /* margin-top: 2em; */
  }

  .header-formerly-paragraph {
    font-size: 1.25em;
    padding: 0.15em 0;
    margin: 0.25em;
  }

  .header-address-paragraph {
    font-size: 1.5em;
    margin-top: 1.25em;
  }

  .header-phone-hours-container {
    align-self: center;
  }

  .header-phone-number {
    font-size: 1.25em;
  }

  .header-hours-span {
    font-size: 1.25em;
  }

  .header-hours-operation-header {
    font-size: 1.5em;
  }

  .nav-bar-links-container {
    height: 3em;
  }

  .nav-bar-nav-link {
    font-size: 16pt;
  }

  .lighting-banner-row-header {
    font-size: 1.5em;
  }

  .home-screen-image-gallery {
    width: 80%;
  }

  .introduction-row-header {
    font-size: 2em;
  }

  .introduction-row-paragraph {
    font-size: 16pt;
  }

  .browse-products-links-container {
    flex-direction: row;
    justify-content: space-around;
    margin: 0.5em 0;
  }

  .home-screen-products-nav-link {
    width: 47%;
    font-size: 20pt;
  }
  
  .home-screen-list-card-container {
    /* height: 26em; */
    height: 21em;
  }

  .brands-container-header {
    font-size: 1.65em;
  }

  .brands-list {
    font-size: 1.35em;
    /* margin: 0; */
    margin: 0.5em 0;
    /* height: 17em; */
    height: 12em;
    justify-content: space-around;
  }

  .brand-list-item {
    height: 2em;
    width: 32%;
  }

  .brand-list-item-anchor {
    width: 100%;
    font-size: 18pt;
  }

  .about-us-container-header {
    font-size: 1.65em;
  }

  .about-us-description {
    font-size: 1.5em;
    padding: 0 1.45em;
  }

  .home-screen-description {
    padding: 0.75em 1em;
  }

  .home-screen-description-paragraph {
    font-size: 1.45em;
  }

  .footer-logo-address-container {
    width: 19em;
  }

  .footer-address {
    font-size: 1.3em;
  }

  .footer-store-hours {
    font-size: 1.3em;
  }

  .footer-store-hours-header {
    font-size: 1.4em;
  }

  .footer-connect-with-us {
    font-size: 1.3em;
  }

  .footer-connect-with-us-header {
    font-size: 1.4em;
  }

  .ceiling-fans-description-header {
    margin-bottom: 0.05em;
    font-size: 3.5em;
  }

  .products-brands-container-header {
    margin-bottom: 0.05em;
    font-size: 3em;
  }

  .lighting-fixtures-description-header {
    margin-bottom: 0.05em;
    font-size: 3.5em;
  }

  .featured-lighting-fixture-products-container-header {
    margin-bottom: 0.05em;
    font-size: 2.5em;
  }

  .featured-products-container-header {
    margin-bottom: 0.05em;
    font-size: 2.5em;
  }
  
  .featured-products-container-paragraph {
  }

  .featured-products-card-navlink {
    margin: 1em 0.5em;
    width: 450px;
  }

  .featured-products-card-image {
    width: 450px;
    height: 450px
  }

  .featured-ceiling-fans-by-brand-container-header {
    margin-bottom: 0.05em;
    font-size: 2.5em;
  }

  .parts-accessories-card {
    /* width: 550px; */
    margin: 1em;
    width: 45%;
  }

  .parts-accessories-card-image {
    /* width: 550px;
    height: 550px; */

  }

  .fan-details-container {
    padding: 0 2em;
  }

  .fan-details-image {
    /* width: 60%; */
    width: 500px;
  }

  .sizes-container {
    align-items: flex-start;
  }

  .sizes-list {
    /* padding-left: 1em; */
  }

  .lighting-fixture-details-image {
    width: 500px;
  }

  .location-row {
    font-size: 1.2em;
  }
  
  .location-row-street-address {
    margin: 0.3em 0 1em 0;
  }

  .location-row-directions {
    margin: 0.5em 0 0.5em 0;
  }

  .contact-us-card {
    font-size: 1.3em;
  }

  .contact-us-card-header {
    margin: 0;
    font-size: 1.5em;
  }

  .contact-us-card-information-address {
    margin-top: 0.5em;
  }

  .contact-us-form-header {
    font-size: 1.5em;
    margin-top: 0;
  }

  .contact-us-form-input-header {
    font-size: 1.2em;
  }

  .contact-us-form-input {
    font-size: 1.1em;
    height: 2em;
  }

  .contact-us-form-message-input {
    height: 20em;
  }

}
